#stars {
	width: 1px;
	height: 1px;
	background: transparent;
	box-shadow: 1487px 822px #ffff, 702px 619px #ffff, 579px 76px #ffff,
		881px 14px #ffff, 1903px 741px #ffff, 1401px 1791px #ffff, 1316px 857px #ffff,
		1628px 1376px #ffff, 1641px 781px #ffff, 1421px 25px #ffff, 525px 254px #ffff,
		683px 1197px #ffff, 1636px 1573px #ffff, 1855px 1220px #ffff, 108px 1424px #ffff,
		906px 1967px #ffff, 205px 1681px #ffff, 1930px 731px #ffff, 1957px 104px #ffff,
		1558px 24px #ffff, 211px 468px #ffff, 2px 1001px #ffff, 664px 1095px #ffff,
		1023px 1573px #ffff, 272px 122px #ffff, 659px 1641px #ffff, 1518px 1633px #ffff,
		1736px 1016px #ffff, 1085px 1911px #ffff, 737px 1784px #ffff, 564px 968px #ffff,
		150px 1277px #ffff, 1848px 505px #ffff, 1067px 1414px #ffff, 1619px 1498px #ffff,
		266px 757px #ffff, 450px 1052px #ffff, 604px 1178px #ffff, 1069px 1215px #ffff,
		1045px 1816px #ffff, 1682px 1111px #ffff, 578px 1875px #ffff, 1197px 20px #ffff,
		736px 1923px #ffff, 1698px 592px #ffff, 491px 1835px #ffff, 106px 1113px #ffff,
		1944px 1749px #ffff, 677px 525px #ffff, 798px 1663px #ffff, 463px 19px #ffff,
		1982px 1091px #ffff, 736px 1597px #ffff, 244px 1625px #ffff, 1699px 1257px #ffff,
		624px 114px #ffff, 1290px 48px #ffff, 1007px 1111px #ffff, 1411px 31px #ffff,
		1661px 1065px #ffff, 1583px 1593px #ffff, 1165px 1985px #ffff, 351px 222px #ffff,
		158px 709px #ffff, 1304px 194px #ffff, 1192px 1923px #ffff, 358px 1642px #ffff,
		889px 1701px #ffff, 424px 1427px #ffff, 1928px 573px #ffff, 1263px 1473px #ffff,
		498px 33px #ffff, 1043px 286px #ffff, 843px 1917px #ffff, 909px 813px #ffff,
		1033px 291px #ffff, 41px 749px #ffff, 1807px 1719px #ffff, 1984px 284px #ffff,
		1983px 1696px #ffff, 1409px 179px #ffff, 1350px 929px #ffff, 332px 1747px #ffff,
		212px 1977px #ffff, 1668px 1254px #ffff, 1585px 1736px #ffff, 783px 85px #ffff,
		1961px 847px #ffff, 1692px 996px #ffff, 1829px 694px #ffff, 965px 891px #ffff,
		1793px 102px #ffff, 1347px 235px #ffff, 1566px 1954px #ffff, 1406px 1765px #ffff,
		1346px 1832px #ffff, 452px 1205px #ffff, 1667px 1688px #ffff, 1337px 1664px #ffff,
		1728px 832px #ffff, 1568px 1213px #ffff, 1980px 357px #ffff, 1096px 624px #ffff,
		210px 636px #ffff, 1247px 1830px #ffff, 1789px 1970px #ffff, 582px 978px #ffff,
		1473px 1002px #ffff, 1042px 440px #ffff, 1189px 879px #ffff, 24px 931px #ffff,
		1656px 1848px #ffff, 401px 1676px #ffff, 192px 1632px #ffff, 970px 1936px #ffff,
		231px 1849px #ffff, 1860px 127px #ffff, 469px 1487px #ffff, 436px 1899px #ffff,
		680px 1290px #ffff, 809px 128px #ffff, 163px 683px #ffff, 1221px 1732px #ffff,
		982px 801px #ffff, 149px 455px #ffff, 39px 1305px #ffff, 248px 1059px #ffff,
		1985px 1040px #ffff, 823px 1174px #ffff, 1823px 12px #ffff, 1558px 1446px #ffff,
		164px 348px #ffff, 504px 311px #ffff, 95px 1735px #ffff, 1817px 560px #ffff,
		1527px 1792px #ffff, 296px 377px #ffff, 1480px 353px #ffff, 1419px 1148px #ffff,
		734px 610px #ffff, 1233px 1076px #ffff, 1495px 198px #ffff, 1722px 1821px #ffff,
		57px 914px #ffff, 81px 1967px #ffff, 157px 1383px #ffff, 478px 1545px #ffff,
		96px 1485px #ffff, 1248px 1516px #ffff, 1989px 1475px #ffff, 730px 61px #ffff,
		646px 342px #ffff, 656px 376px #ffff, 809px 1394px #ffff, 483px 1916px #ffff,
		695px 1985px #ffff, 954px 279px #ffff, 1704px 234px #ffff, 204px 1621px #ffff,
		490px 414px #ffff, 929px 1852px #ffff, 383px 1017px #ffff, 1319px 14px #ffff,
		103px 1147px #ffff, 1780px 62px #ffff, 764px 318px #ffff, 950px 1468px #ffff,
		767px 8px #ffff, 1038px 763px #ffff, 62px 680px #ffff, 1619px 817px #ffff,
		1462px 1819px #ffff, 1340px 1956px #ffff, 1802px 902px #ffff, 1739px 1865px #ffff,
		886px 854px #ffff, 1760px 1693px #ffff, 1571px 1175px #ffff, 1149px 1748px #ffff,
		1521px 1213px #ffff, 575px 162px #ffff, 1904px 456px #ffff, 1399px 416px #ffff,
		1325px 1118px #ffff, 1848px 478px #ffff, 1447px 841px #ffff, 457px 1619px #ffff,
		1202px 1049px #ffff, 397px 682px #ffff, 465px 1042px #ffff, 1264px 1033px #ffff,
		1304px 1004px #ffff, 649px 1586px #ffff, 97px 1308px #ffff, 21px 619px #ffff,
		469px 1742px #ffff, 81px 1745px #ffff, 1735px 1208px #ffff, 805px 1206px #ffff,
		717px 492px #ffff, 187px 1317px #ffff, 860px 1290px #ffff, 1964px 1712px #ffff,
		1036px 142px #ffff, 1238px 442px #ffff, 1766px 423px #ffff, 925px 353px #ffff,
		569px 5px #ffff, 1338px 383px #ffff, 1321px 90px #ffff, 1613px 749px #ffff,
		409px 1835px #ffff, 642px 715px #ffff, 1402px 444px #ffff, 1669px 197px #ffff,
		291px 1022px #ffff, 632px 1847px #ffff, 678px 810px #ffff, 570px 798px #ffff,
		1521px 681px #ffff, 309px 176px #ffff, 1913px 882px #ffff, 1129px 1601px #ffff,
		1310px 1490px #ffff, 883px 1925px #ffff, 1354px 140px #ffff, 1474px 628px #ffff,
		1997px 230px #ffff, 351px 1212px #ffff, 576px 706px #ffff, 1210px 1325px #ffff,
		1927px 315px #ffff, 1505px 1249px #ffff, 699px 1811px #ffff, 1428px 913px #ffff,
		691px 1811px #ffff, 977px 1645px #ffff, 330px 260px #ffff, 1632px 1148px #ffff,
		878px 1070px #ffff, 390px 1127px #ffff, 1915px 1956px #ffff, 805px 314px #ffff,
		260px 623px #ffff, 1471px 1629px #ffff, 1333px 1800px #ffff, 1158px 1712px #ffff,
		813px 641px #ffff, 608px 761px #ffff, 529px 1163px #ffff, 1916px 1476px #ffff,
		308px 1823px #ffff, 910px 894px #ffff, 839px 431px #ffff, 1638px 1599px #ffff,
		128px 1590px #ffff, 1157px 1788px #ffff, 1035px 773px #ffff, 389px 277px #ffff,
		553px 1014px #ffff, 1978px 668px #ffff, 615px 1056px #ffff, 944px 1726px #ffff,
		974px 324px #ffff, 1448px 349px #ffff, 848px 405px #ffff, 1757px 1457px #ffff,
		468px 1754px #ffff, 714px 414px #ffff, 697px 592px #ffff, 1434px 1246px #ffff,
		258px 1687px #ffff, 953px 1354px #ffff, 922px 179px #ffff, 1899px 662px #ffff,
		1103px 448px #ffff, 1205px 799px #ffff, 1256px 1247px #ffff, 1772px 194px #ffff,
		1809px 1285px #ffff, 518px 698px #ffff, 1906px 332px #ffff, 1182px 536px #ffff,
		1758px 354px #ffff, 131px 1042px #ffff, 923px 1832px #ffff, 1117px 1198px #ffff,
		143px 1532px #ffff, 1401px 395px #ffff, 1359px 1550px #ffff, 615px 206px #ffff,
		694px 475px #ffff, 530px 1675px #ffff, 1413px 1544px #ffff, 1330px 1429px #ffff,
		1433px 1453px #ffff, 1271px 846px #ffff, 1369px 1790px #ffff, 517px 1392px #ffff,
		897px 1434px #ffff, 1906px 1012px #ffff, 1523px 1474px #ffff, 666px 7px #ffff,
		598px 1273px #ffff, 1544px 541px #ffff, 248px 268px #ffff, 1992px 848px #ffff,
		571px 870px #ffff, 1228px 679px #ffff, 1096px 187px #ffff, 1553px 15px #ffff,
		523px 779px #ffff, 140px 231px #ffff, 659px 577px #ffff, 463px 727px #ffff,
		986px 623px #ffff, 1822px 1062px #ffff, 596px 1460px #ffff, 462px 115px #ffff,
		1318px 516px #ffff, 409px 729px #ffff, 710px 976px #ffff, 1163px 1539px #ffff,
		1542px 1362px #ffff, 394px 1840px #ffff, 904px 1541px #ffff, 1549px 538px #ffff,
		1594px 1384px #ffff, 1030px 1465px #ffff, 599px 544px #ffff, 1933px 241px #ffff,
		1281px 287px #ffff, 729px 1282px #ffff, 819px 364px #ffff, 797px 1590px #ffff,
		1023px 1973px #ffff, 1708px 961px #ffff, 293px 1953px #ffff, 1685px 1879px #ffff,
		1285px 1995px #ffff, 1380px 219px #ffff, 1715px 1980px #ffff, 218px 108px #ffff,
		700px 711px #ffff, 471px 1764px #ffff, 10px 1084px #ffff, 1841px 1778px #ffff,
		1765px 1548px #ffff, 712px 1703px #ffff, 1866px 1805px #ffff, 1896px 760px #ffff,
		1391px 40px #ffff, 761px 1516px #ffff, 1329px 584px #ffff, 385px 1141px #ffff,
		1238px 21px #ffff, 1070px 486px #ffff, 1096px 312px #ffff, 1254px 1674px #ffff,
		549px 1349px #ffff, 1618px 986px #ffff, 707px 1741px #ffff, 1718px 1514px #ffff,
		481px 597px #ffff, 1690px 1472px #ffff, 1728px 919px #ffff, 173px 1196px #ffff,
		1778px 1257px #ffff, 1207px 649px #ffff, 447px 634px #ffff, 1306px 1444px #ffff,
		936px 1818px #ffff, 855px 1015px #ffff, 1421px 332px #ffff, 1138px 1889px #ffff,
		1974px 694px #ffff, 715px 308px #ffff, 757px 155px #ffff, 18px 107px #ffff,
		561px 378px #ffff, 144px 798px #ffff, 1184px 967px #ffff, 1263px 1130px #ffff,
		1382px 895px #ffff, 149px 175px #ffff, 1297px 839px #ffff, 943px 454px #ffff,
		178px 1165px #ffff, 477px 407px #ffff, 416px 219px #ffff, 1056px 1491px #ffff,
		625px 816px #ffff, 100px 1537px #ffff, 1074px 520px #ffff, 1031px 1453px #ffff,
		1377px 614px #ffff, 572px 1314px #ffff, 1513px 1235px #ffff, 1920px 1211px #ffff,
		330px 107px #ffff, 1260px 772px #ffff, 1198px 84px #ffff, 1409px 139px #ffff,
		758px 98px #ffff, 698px 1771px #ffff, 273px 275px #ffff, 163px 200px #ffff,
		1665px 732px #ffff, 95px 600px #ffff, 522px 122px #ffff, 76px 804px #ffff,
		1669px 1526px #ffff, 1881px 465px #ffff, 1043px 1919px #ffff, 1521px 827px #ffff,
		1197px 1997px #ffff, 489px 593px #ffff, 1899px 722px #ffff, 1575px 332px #ffff,
		327px 718px #ffff, 1959px 1378px #ffff, 455px 1333px #ffff, 729px 822px #ffff,
		748px 126px #ffff, 444px 445px #ffff, 1856px 262px #ffff, 1140px 921px #ffff,
		1680px 1227px #ffff, 1144px 1742px #ffff, 1922px 384px #ffff, 1015px 496px #ffff,
		1213px 831px #ffff, 1793px 898px #ffff, 35px 159px #ffff, 571px 1505px #ffff,
		726px 42px #ffff, 1216px 1214px #ffff, 938px 50px #ffff, 322px 1017px #ffff,
		1222px 523px #ffff, 660px 1174px #ffff, 355px 859px #ffff, 1381px 1648px #ffff,
		8px 1546px #ffff, 1768px 17px #ffff, 301px 1159px #ffff, 1550px 185px #ffff,
		1289px 656px #ffff, 1187px 106px #ffff, 669px 1498px #ffff, 555px 323px #ffff,
		454px 288px #ffff, 72px 271px #ffff, 445px 1713px #ffff, 136px 10px #ffff,
		1810px 1149px #ffff, 1445px 794px #ffff, 406px 1822px #ffff, 1994px 741px #ffff,
		1837px 1564px #ffff, 1264px 80px #ffff, 273px 845px #ffff, 322px 1693px #ffff,
		103px 463px #ffff, 689px 1161px #ffff, 353px 717px #ffff, 1275px 1895px #ffff,
		1331px 130px #ffff, 1251px 1424px #ffff, 1003px 1183px #ffff, 721px 904px #ffff,
		1261px 416px #ffff, 600px 577px #ffff, 384px 910px #ffff, 1248px 1547px #ffff,
		955px 734px #ffff, 686px 891px #ffff, 196px 1523px #ffff, 831px 327px #ffff,
		845px 1486px #ffff, 1553px 1438px #ffff, 1133px 1152px #ffff, 356px 1087px #ffff,
		1503px 807px #ffff, 1098px 1396px #ffff, 94px 1934px #ffff, 1195px 58px #ffff,
		748px 771px #ffff, 940px 570px #ffff, 574px 651px #ffff, 835px 533px #ffff,
		42px 1210px #ffff, 496px 1791px #ffff, 1610px 296px #ffff, 1446px 1574px #ffff,
		261px 1765px #ffff, 818px 368px #ffff, 573px 743px #ffff, 545px 333px #ffff,
		1500px 1567px #ffff, 1789px 448px #ffff, 1139px 1559px #ffff, 1607px 1127px #ffff,
		1980px 874px #ffff, 731px 1559px #ffff, 898px 158px #ffff, 1641px 1053px #ffff,
		53px 377px #ffff, 1879px 1370px #ffff, 1377px 899px #ffff, 1416px 829px #ffff,
		863px 1545px #ffff, 1219px 1935px #ffff, 1638px 320px #ffff, 239px 1807px #ffff,
		851px 464px #ffff, 1868px 1843px #ffff, 679px 203px #ffff, 1762px 842px #ffff,
		59px 1986px #ffff, 1142px 1911px #ffff, 838px 1030px #ffff, 1370px 1269px #ffff,
		781px 274px #ffff, 1193px 551px #ffff, 638px 478px #ffff, 456px 1854px #ffff,
		424px 690px #ffff, 13px 600px #ffff, 306px 1867px #ffff, 384px 1298px #ffff,
		762px 342px #ffff, 1780px 1196px #ffff, 108px 1138px #ffff, 1671px 866px #ffff,
		831px 1515px #ffff, 218px 1455px #ffff, 1315px 1494px #ffff, 1365px 1181px #ffff,
		168px 986px #ffff, 349px 506px #ffff, 974px 1437px #ffff, 1605px 908px #ffff,
		1036px 1208px #ffff, 1082px 1407px #ffff, 916px 827px #ffff, 1526px 566px #ffff,
		1159px 1877px #ffff, 210px 1180px #ffff, 979px 1815px #ffff, 130px 1358px #ffff,
		1961px 1963px #ffff, 771px 591px #ffff, 1905px 487px #ffff, 1816px 1565px #ffff,
		333px 1600px #ffff, 1842px 1774px #ffff, 1573px 135px #ffff, 1385px 1889px #ffff,
		1611px 1033px #ffff, 367px 1236px #ffff, 779px 1022px #ffff, 1851px 62px #ffff,
		478px 257px #ffff, 1907px 1960px #ffff, 1435px 532px #ffff, 662px 8px #ffff,
		1027px 1924px #ffff, 489px 101px #ffff, 76px 1875px #ffff, 657px 1682px #ffff,
		1993px 1955px #ffff, 1432px 1845px #ffff, 1530px 1729px #ffff, 1211px 896px #ffff,
		1525px 133px #ffff, 1445px 803px #ffff, 530px 62px #ffff, 242px 1513px #ffff,
		256px 933px #ffff, 1701px 936px #ffff, 756px 1893px #ffff, 1251px 1298px #ffff,
		380px 1399px #ffff, 1455px 302px #ffff, 1729px 1171px #ffff, 585px 49px #ffff,
		1961px 1124px #ffff, 1241px 552px #ffff, 1205px 40px #ffff, 1938px 1247px #ffff,
		1469px 89px #ffff, 239px 1939px #ffff, 698px 448px #ffff, 1795px 346px #ffff,
		151px 487px #ffff, 102px 126px #ffff, 1184px 418px #ffff, 1625px 1418px #ffff,
		29px 554px #ffff, 1288px 1274px #ffff, 791px 1308px #ffff, 151px 934px #ffff,
		1574px 386px #ffff, 1214px 1703px #ffff, 1569px 1424px #ffff, 951px 1830px #ffff,
		1728px 805px #ffff, 312px 1987px #ffff, 1468px 529px #ffff, 1153px 1146px #ffff,
		1262px 1021px #ffff, 1648px 1766px #ffff, 306px 70px #ffff, 754px 1165px #ffff,
		1930px 482px #ffff, 1814px 1593px #ffff, 1066px 822px #ffff, 1250px 877px #ffff,
		465px 1885px #ffff, 1649px 1281px #ffff, 1682px 1601px #ffff, 1367px 1233px #ffff,
		91px 1327px #ffff, 1485px 1355px #ffff, 501px 1673px #ffff, 1649px 1259px #ffff,
		1986px 948px #ffff, 1266px 1829px #ffff, 135px 1503px #ffff, 647px 296px #ffff,
		155px 341px #ffff, 649px 1896px #ffff, 1617px 334px #ffff, 223px 1507px #ffff,
		584px 146px #ffff, 200px 316px #ffff, 575px 1831px #ffff, 1374px 1809px #ffff,
		1445px 386px #ffff, 475px 1249px #ffff, 1954px 89px #ffff, 1756px 1192px #ffff,
		8px 671px #ffff, 1366px 1694px #ffff, 1831px 306px #ffff, 925px 113px #ffff,
		160px 1174px #ffff, 534px 1908px #ffff, 553px 273px #ffff, 423px 45px #ffff,
		1305px 1098px #ffff, 187px 90px #ffff, 1993px 1396px #ffff, 842px 1270px #ffff,
		1137px 554px #ffff, 351px 558px #ffff, 1576px 1070px #ffff, 437px 312px #ffff,
		286px 1019px #ffff, 257px 272px #ffff, 1242px 1957px #ffff, 1963px 1693px #ffff,
		772px 447px #ffff, 1641px 508px #ffff, 712px 100px #ffff, 1307px 1015px #ffff,
		742px 327px #ffff, 281px 1814px #ffff, 1398px 1051px #ffff, 167px 9px #ffff,
		328px 1489px #ffff, 1814px 1804px #ffff, 946px 25px #ffff, 549px 1705px #ffff,
		217px 1477px #ffff, 1937px 280px #ffff, 182px 241px #ffff, 711px 1365px #ffff,
		1898px 1043px #ffff, 1807px 346px #ffff, 1787px 1253px #ffff, 247px 66px #ffff,
		1171px 1592px #ffff, 846px 895px #ffff, 1507px 720px #ffff, 787px 574px #ffff,
		1593px 1267px #ffff, 843px 1902px #ffff, 15px 1454px #ffff, 539px 1420px #ffff,
		1856px 974px #ffff, 340px 663px #ffff, 1579px 1091px #ffff, 809px 111px #ffff,
		32px 1128px #ffff, 69px 1852px #ffff, 878px 300px #ffff, 730px 726px #ffff,
		1137px 68px #ffff;
	animation: animStar 50s linear infinite;
    
	&:after {
		content: " ";
		position: absolute;
		top: 2000px;
		width: 1px;
		height: 1px;
		background: transparent;
		box-shadow: 1487px 822px #ffff, 702px 619px #ffff, 579px 76px #ffff,
			881px 14px #ffff, 1903px 741px #ffff, 1401px 1791px #ffff, 1316px 857px #ffff,
			1628px 1376px #ffff, 1641px 781px #ffff, 1421px 25px #ffff, 525px 254px #ffff,
			683px 1197px #ffff, 1636px 1573px #ffff, 1855px 1220px #ffff, 108px 1424px #ffff,
			906px 1967px #ffff, 205px 1681px #ffff, 1930px 731px #ffff, 1957px 104px #ffff,
			1558px 24px #ffff, 211px 468px #ffff, 2px 1001px #ffff, 664px 1095px #ffff,
			1023px 1573px #ffff, 272px 122px #ffff, 659px 1641px #ffff, 1518px 1633px #ffff,
			1736px 1016px #ffff, 1085px 1911px #ffff, 737px 1784px #ffff, 564px 968px #ffff,
			150px 1277px #ffff, 1848px 505px #ffff, 1067px 1414px #ffff, 1619px 1498px #ffff,
			266px 757px #ffff, 450px 1052px #ffff, 604px 1178px #ffff, 1069px 1215px #ffff,
			1045px 1816px #ffff, 1682px 1111px #ffff, 578px 1875px #ffff, 1197px 20px #ffff,
			736px 1923px #ffff, 1698px 592px #ffff, 491px 1835px #ffff, 106px 1113px #ffff,
			1944px 1749px #ffff, 677px 525px #ffff, 798px 1663px #ffff, 463px 19px #ffff,
			1982px 1091px #ffff, 736px 1597px #ffff, 244px 1625px #ffff, 1699px 1257px #ffff,
			624px 114px #ffff, 1290px 48px #ffff, 1007px 1111px #ffff, 1411px 31px #ffff,
			1661px 1065px #ffff, 1583px 1593px #ffff, 1165px 1985px #ffff, 351px 222px #ffff,
			158px 709px #ffff, 1304px 194px #ffff, 1192px 1923px #ffff, 358px 1642px #ffff,
			889px 1701px #ffff, 424px 1427px #ffff, 1928px 573px #ffff, 1263px 1473px #ffff,
			498px 33px #ffff, 1043px 286px #ffff, 843px 1917px #ffff, 909px 813px #ffff,
			1033px 291px #ffff, 41px 749px #ffff, 1807px 1719px #ffff, 1984px 284px #ffff,
			1983px 1696px #ffff, 1409px 179px #ffff, 1350px 929px #ffff, 332px 1747px #ffff,
			212px 1977px #ffff, 1668px 1254px #ffff, 1585px 1736px #ffff, 783px 85px #ffff,
			1961px 847px #ffff, 1692px 996px #ffff, 1829px 694px #ffff, 965px 891px #ffff,
			1793px 102px #ffff, 1347px 235px #ffff, 1566px 1954px #ffff, 1406px 1765px #ffff,
			1346px 1832px #ffff, 452px 1205px #ffff, 1667px 1688px #ffff, 1337px 1664px #ffff,
			1728px 832px #ffff, 1568px 1213px #ffff, 1980px 357px #ffff, 1096px 624px #ffff,
			210px 636px #ffff, 1247px 1830px #ffff, 1789px 1970px #ffff, 582px 978px #ffff,
			1473px 1002px #ffff, 1042px 440px #ffff, 1189px 879px #ffff, 24px 931px #ffff,
			1656px 1848px #ffff, 401px 1676px #ffff, 192px 1632px #ffff, 970px 1936px #ffff,
			231px 1849px #ffff, 1860px 127px #ffff, 469px 1487px #ffff, 436px 1899px #ffff,
			680px 1290px #ffff, 809px 128px #ffff, 163px 683px #ffff, 1221px 1732px #ffff,
			982px 801px #ffff, 149px 455px #ffff, 39px 1305px #ffff, 248px 1059px #ffff,
			1985px 1040px #ffff, 823px 1174px #ffff, 1823px 12px #ffff, 1558px 1446px #ffff,
			164px 348px #ffff, 504px 311px #ffff, 95px 1735px #ffff, 1817px 560px #ffff,
			1527px 1792px #ffff, 296px 377px #ffff, 1480px 353px #ffff, 1419px 1148px #ffff,
			734px 610px #ffff, 1233px 1076px #ffff, 1495px 198px #ffff, 1722px 1821px #ffff,
			57px 914px #ffff, 81px 1967px #ffff, 157px 1383px #ffff, 478px 1545px #ffff,
			96px 1485px #ffff, 1248px 1516px #ffff, 1989px 1475px #ffff, 730px 61px #ffff,
			646px 342px #ffff, 656px 376px #ffff, 809px 1394px #ffff, 483px 1916px #ffff,
			695px 1985px #ffff, 954px 279px #ffff, 1704px 234px #ffff, 204px 1621px #ffff,
			490px 414px #ffff, 929px 1852px #ffff, 383px 1017px #ffff, 1319px 14px #ffff,
			103px 1147px #ffff, 1780px 62px #ffff, 764px 318px #ffff, 950px 1468px #ffff,
			767px 8px #ffff, 1038px 763px #ffff, 62px 680px #ffff, 1619px 817px #ffff,
			1462px 1819px #ffff, 1340px 1956px #ffff, 1802px 902px #ffff, 1739px 1865px #ffff,
			886px 854px #ffff, 1760px 1693px #ffff, 1571px 1175px #ffff, 1149px 1748px #ffff,
			1521px 1213px #ffff, 575px 162px #ffff, 1904px 456px #ffff, 1399px 416px #ffff,
			1325px 1118px #ffff, 1848px 478px #ffff, 1447px 841px #ffff, 457px 1619px #ffff,
			1202px 1049px #ffff, 397px 682px #ffff, 465px 1042px #ffff, 1264px 1033px #ffff,
			1304px 1004px #ffff, 649px 1586px #ffff, 97px 1308px #ffff, 21px 619px #ffff,
			469px 1742px #ffff, 81px 1745px #ffff, 1735px 1208px #ffff, 805px 1206px #ffff,
			717px 492px #ffff, 187px 1317px #ffff, 860px 1290px #ffff, 1964px 1712px #ffff,
			1036px 142px #ffff, 1238px 442px #ffff, 1766px 423px #ffff, 925px 353px #ffff,
			569px 5px #ffff, 1338px 383px #ffff, 1321px 90px #ffff, 1613px 749px #ffff,
			409px 1835px #ffff, 642px 715px #ffff, 1402px 444px #ffff, 1669px 197px #ffff,
			291px 1022px #ffff, 632px 1847px #ffff, 678px 810px #ffff, 570px 798px #ffff,
			1521px 681px #ffff, 309px 176px #ffff, 1913px 882px #ffff, 1129px 1601px #ffff,
			1310px 1490px #ffff, 883px 1925px #ffff, 1354px 140px #ffff, 1474px 628px #ffff,
			1997px 230px #ffff, 351px 1212px #ffff, 576px 706px #ffff, 1210px 1325px #ffff,
			1927px 315px #ffff, 1505px 1249px #ffff, 699px 1811px #ffff, 1428px 913px #ffff,
			691px 1811px #ffff, 977px 1645px #ffff, 330px 260px #ffff, 1632px 1148px #ffff,
			878px 1070px #ffff, 390px 1127px #ffff, 1915px 1956px #ffff, 805px 314px #ffff,
			260px 623px #ffff, 1471px 1629px #ffff, 1333px 1800px #ffff, 1158px 1712px #ffff,
			813px 641px #ffff, 608px 761px #ffff, 529px 1163px #ffff, 1916px 1476px #ffff,
			308px 1823px #ffff, 910px 894px #ffff, 839px 431px #ffff, 1638px 1599px #ffff,
			128px 1590px #ffff, 1157px 1788px #ffff, 1035px 773px #ffff, 389px 277px #ffff,
			553px 1014px #ffff, 1978px 668px #ffff, 615px 1056px #ffff, 944px 1726px #ffff,
			974px 324px #ffff, 1448px 349px #ffff, 848px 405px #ffff, 1757px 1457px #ffff,
			468px 1754px #ffff, 714px 414px #ffff, 697px 592px #ffff, 1434px 1246px #ffff,
			258px 1687px #ffff, 953px 1354px #ffff, 922px 179px #ffff, 1899px 662px #ffff,
			1103px 448px #ffff, 1205px 799px #ffff, 1256px 1247px #ffff, 1772px 194px #ffff,
			1809px 1285px #ffff, 518px 698px #ffff, 1906px 332px #ffff, 1182px 536px #ffff,
			1758px 354px #ffff, 131px 1042px #ffff, 923px 1832px #ffff, 1117px 1198px #ffff,
			143px 1532px #ffff, 1401px 395px #ffff, 1359px 1550px #ffff, 615px 206px #ffff,
			694px 475px #ffff, 530px 1675px #ffff, 1413px 1544px #ffff, 1330px 1429px #ffff,
			1433px 1453px #ffff, 1271px 846px #ffff, 1369px 1790px #ffff, 517px 1392px #ffff,
			897px 1434px #ffff, 1906px 1012px #ffff, 1523px 1474px #ffff, 666px 7px #ffff,
			598px 1273px #ffff, 1544px 541px #ffff, 248px 268px #ffff, 1992px 848px #ffff,
			571px 870px #ffff, 1228px 679px #ffff, 1096px 187px #ffff, 1553px 15px #ffff,
			523px 779px #ffff, 140px 231px #ffff, 659px 577px #ffff, 463px 727px #ffff,
			986px 623px #ffff, 1822px 1062px #ffff, 596px 1460px #ffff, 462px 115px #ffff,
			1318px 516px #ffff, 409px 729px #ffff, 710px 976px #ffff, 1163px 1539px #ffff,
			1542px 1362px #ffff, 394px 1840px #ffff, 904px 1541px #ffff, 1549px 538px #ffff,
			1594px 1384px #ffff, 1030px 1465px #ffff, 599px 544px #ffff, 1933px 241px #ffff,
			1281px 287px #ffff, 729px 1282px #ffff, 819px 364px #ffff, 797px 1590px #ffff,
			1023px 1973px #ffff, 1708px 961px #ffff, 293px 1953px #ffff, 1685px 1879px #ffff,
			1285px 1995px #ffff, 1380px 219px #ffff, 1715px 1980px #ffff, 218px 108px #ffff,
			700px 711px #ffff, 471px 1764px #ffff, 10px 1084px #ffff, 1841px 1778px #ffff,
			1765px 1548px #ffff, 712px 1703px #ffff, 1866px 1805px #ffff, 1896px 760px #ffff,
			1391px 40px #ffff, 761px 1516px #ffff, 1329px 584px #ffff, 385px 1141px #ffff,
			1238px 21px #ffff, 1070px 486px #ffff, 1096px 312px #ffff, 1254px 1674px #ffff,
			549px 1349px #ffff, 1618px 986px #ffff, 707px 1741px #ffff, 1718px 1514px #ffff,
			481px 597px #ffff, 1690px 1472px #ffff, 1728px 919px #ffff, 173px 1196px #ffff,
			1778px 1257px #ffff, 1207px 649px #ffff, 447px 634px #ffff, 1306px 1444px #ffff,
			936px 1818px #ffff, 855px 1015px #ffff, 1421px 332px #ffff, 1138px 1889px #ffff,
			1974px 694px #ffff, 715px 308px #ffff, 757px 155px #ffff, 18px 107px #ffff,
			561px 378px #ffff, 144px 798px #ffff, 1184px 967px #ffff, 1263px 1130px #ffff,
			1382px 895px #ffff, 149px 175px #ffff, 1297px 839px #ffff, 943px 454px #ffff,
			178px 1165px #ffff, 477px 407px #ffff, 416px 219px #ffff, 1056px 1491px #ffff,
			625px 816px #ffff, 100px 1537px #ffff, 1074px 520px #ffff, 1031px 1453px #ffff,
			1377px 614px #ffff, 572px 1314px #ffff, 1513px 1235px #ffff, 1920px 1211px #ffff,
			330px 107px #ffff, 1260px 772px #ffff, 1198px 84px #ffff, 1409px 139px #ffff,
			758px 98px #ffff, 698px 1771px #ffff, 273px 275px #ffff, 163px 200px #ffff,
			1665px 732px #ffff, 95px 600px #ffff, 522px 122px #ffff, 76px 804px #ffff,
			1669px 1526px #ffff, 1881px 465px #ffff, 1043px 1919px #ffff, 1521px 827px #ffff,
			1197px 1997px #ffff, 489px 593px #ffff, 1899px 722px #ffff, 1575px 332px #ffff,
			327px 718px #ffff, 1959px 1378px #ffff, 455px 1333px #ffff, 729px 822px #ffff,
			748px 126px #ffff, 444px 445px #ffff, 1856px 262px #ffff, 1140px 921px #ffff,
			1680px 1227px #ffff, 1144px 1742px #ffff, 1922px 384px #ffff, 1015px 496px #ffff,
			1213px 831px #ffff, 1793px 898px #ffff, 35px 159px #ffff, 571px 1505px #ffff,
			726px 42px #ffff, 1216px 1214px #ffff, 938px 50px #ffff, 322px 1017px #ffff,
			1222px 523px #ffff, 660px 1174px #ffff, 355px 859px #ffff, 1381px 1648px #ffff,
			8px 1546px #ffff, 1768px 17px #ffff, 301px 1159px #ffff, 1550px 185px #ffff,
			1289px 656px #ffff, 1187px 106px #ffff, 669px 1498px #ffff, 555px 323px #ffff,
			454px 288px #ffff, 72px 271px #ffff, 445px 1713px #ffff, 136px 10px #ffff,
			1810px 1149px #ffff, 1445px 794px #ffff, 406px 1822px #ffff, 1994px 741px #ffff,
			1837px 1564px #ffff, 1264px 80px #ffff, 273px 845px #ffff, 322px 1693px #ffff,
			103px 463px #ffff, 689px 1161px #ffff, 353px 717px #ffff, 1275px 1895px #ffff,
			1331px 130px #ffff, 1251px 1424px #ffff, 1003px 1183px #ffff, 721px 904px #ffff,
			1261px 416px #ffff, 600px 577px #ffff, 384px 910px #ffff, 1248px 1547px #ffff,
			955px 734px #ffff, 686px 891px #ffff, 196px 1523px #ffff, 831px 327px #ffff,
			845px 1486px #ffff, 1553px 1438px #ffff, 1133px 1152px #ffff, 356px 1087px #ffff,
			1503px 807px #ffff, 1098px 1396px #ffff, 94px 1934px #ffff, 1195px 58px #ffff,
			748px 771px #ffff, 940px 570px #ffff, 574px 651px #ffff, 835px 533px #ffff,
			42px 1210px #ffff, 496px 1791px #ffff, 1610px 296px #ffff, 1446px 1574px #ffff,
			261px 1765px #ffff, 818px 368px #ffff, 573px 743px #ffff, 545px 333px #ffff,
			1500px 1567px #ffff, 1789px 448px #ffff, 1139px 1559px #ffff, 1607px 1127px #ffff,
			1980px 874px #ffff, 731px 1559px #ffff, 898px 158px #ffff, 1641px 1053px #ffff,
			53px 377px #ffff, 1879px 1370px #ffff, 1377px 899px #ffff, 1416px 829px #ffff,
			863px 1545px #ffff, 1219px 1935px #ffff, 1638px 320px #ffff, 239px 1807px #ffff,
			851px 464px #ffff, 1868px 1843px #ffff, 679px 203px #ffff, 1762px 842px #ffff,
			59px 1986px #ffff, 1142px 1911px #ffff, 838px 1030px #ffff, 1370px 1269px #ffff,
			781px 274px #ffff, 1193px 551px #ffff, 638px 478px #ffff, 456px 1854px #ffff,
			424px 690px #ffff, 13px 600px #ffff, 306px 1867px #ffff, 384px 1298px #ffff,
			762px 342px #ffff, 1780px 1196px #ffff, 108px 1138px #ffff, 1671px 866px #ffff,
			831px 1515px #ffff, 218px 1455px #ffff, 1315px 1494px #ffff, 1365px 1181px #ffff,
			168px 986px #ffff, 349px 506px #ffff, 974px 1437px #ffff, 1605px 908px #ffff,
			1036px 1208px #ffff, 1082px 1407px #ffff, 916px 827px #ffff, 1526px 566px #ffff,
			1159px 1877px #ffff, 210px 1180px #ffff, 979px 1815px #ffff, 130px 1358px #ffff,
			1961px 1963px #ffff, 771px 591px #ffff, 1905px 487px #ffff, 1816px 1565px #ffff,
			333px 1600px #ffff, 1842px 1774px #ffff, 1573px 135px #ffff, 1385px 1889px #ffff,
			1611px 1033px #ffff, 367px 1236px #ffff, 779px 1022px #ffff, 1851px 62px #ffff,
			478px 257px #ffff, 1907px 1960px #ffff, 1435px 532px #ffff, 662px 8px #ffff,
			1027px 1924px #ffff, 489px 101px #ffff, 76px 1875px #ffff, 657px 1682px #ffff,
			1993px 1955px #ffff, 1432px 1845px #ffff, 1530px 1729px #ffff, 1211px 896px #ffff,
			1525px 133px #ffff, 1445px 803px #ffff, 530px 62px #ffff, 242px 1513px #ffff,
			256px 933px #ffff, 1701px 936px #ffff, 756px 1893px #ffff, 1251px 1298px #ffff,
			380px 1399px #ffff, 1455px 302px #ffff, 1729px 1171px #ffff, 585px 49px #ffff,
			1961px 1124px #ffff, 1241px 552px #ffff, 1205px 40px #ffff, 1938px 1247px #ffff,
			1469px 89px #ffff, 239px 1939px #ffff, 698px 448px #ffff, 1795px 346px #ffff,
			151px 487px #ffff, 102px 126px #ffff, 1184px 418px #ffff, 1625px 1418px #ffff,
			29px 554px #ffff, 1288px 1274px #ffff, 791px 1308px #ffff, 151px 934px #ffff,
			1574px 386px #ffff, 1214px 1703px #ffff, 1569px 1424px #ffff, 951px 1830px #ffff,
			1728px 805px #ffff, 312px 1987px #ffff, 1468px 529px #ffff, 1153px 1146px #ffff,
			1262px 1021px #ffff, 1648px 1766px #ffff, 306px 70px #ffff, 754px 1165px #ffff,
			1930px 482px #ffff, 1814px 1593px #ffff, 1066px 822px #ffff, 1250px 877px #ffff,
			465px 1885px #ffff, 1649px 1281px #ffff, 1682px 1601px #ffff, 1367px 1233px #ffff,
			91px 1327px #ffff, 1485px 1355px #ffff, 501px 1673px #ffff, 1649px 1259px #ffff,
			1986px 948px #ffff, 1266px 1829px #ffff, 135px 1503px #ffff, 647px 296px #ffff,
			155px 341px #ffff, 649px 1896px #ffff, 1617px 334px #ffff, 223px 1507px #ffff,
			584px 146px #ffff, 200px 316px #ffff, 575px 1831px #ffff, 1374px 1809px #ffff,
			1445px 386px #ffff, 475px 1249px #ffff, 1954px 89px #ffff, 1756px 1192px #ffff,
			8px 671px #ffff, 1366px 1694px #ffff, 1831px 306px #ffff, 925px 113px #ffff,
			160px 1174px #ffff, 534px 1908px #ffff, 553px 273px #ffff, 423px 45px #ffff,
			1305px 1098px #ffff, 187px 90px #ffff, 1993px 1396px #ffff, 842px 1270px #ffff,
			1137px 554px #ffff, 351px 558px #ffff, 1576px 1070px #ffff, 437px 312px #ffff,
			286px 1019px #ffff, 257px 272px #ffff, 1242px 1957px #ffff, 1963px 1693px #ffff,
			772px 447px #ffff, 1641px 508px #ffff, 712px 100px #ffff, 1307px 1015px #ffff,
			742px 327px #ffff, 281px 1814px #ffff, 1398px 1051px #ffff, 167px 9px #ffff,
			328px 1489px #ffff, 1814px 1804px #ffff, 946px 25px #ffff, 549px 1705px #ffff,
			217px 1477px #ffff, 1937px 280px #ffff, 182px 241px #ffff, 711px 1365px #ffff,
			1898px 1043px #ffff, 1807px 346px #ffff, 1787px 1253px #ffff, 247px 66px #ffff,
			1171px 1592px #ffff, 846px 895px #ffff, 1507px 720px #ffff, 787px 574px #ffff,
			1593px 1267px #ffff, 843px 1902px #ffff, 15px 1454px #ffff, 539px 1420px #ffff,
			1856px 974px #ffff, 340px 663px #ffff, 1579px 1091px #ffff, 809px 111px #ffff,
			32px 1128px #ffff, 69px 1852px #ffff, 878px 300px #ffff, 730px 726px #ffff,
			1137px 68px #ffff;
	}
}

#stars2 {
	width: 2px;
	height: 2px;
	background: transparent;
	box-shadow: 592px 134px #AA076B, 346px 741px #AA076B, 1000px 1781px #AA076B,
		996px 728px #AA076B, 1085px 1462px #AA076B, 526px 1128px #AA076B, 1951px 557px #AA076B,
		673px 92px #AA076B, 415px 776px #AA076B, 1570px 643px #AA076B, 121px 697px #AA076B,
		782px 1847px #AA076B, 1853px 256px #AA076B, 910px 195px #AA076B, 362px 1201px #AA076B,
		52px 1627px #AA076B, 387px 899px #AA076B, 547px 1995px #AA076B, 1298px 440px #AA076B,
		1474px 1713px #AA076B, 1109px 1322px #AA076B, 1720px 1237px #AA076B, 473px 1196px #AA076B,
		1682px 580px #AA076B, 1476px 655px #AA076B, 1254px 731px #AA076B, 1069px 1840px #AA076B,
		291px 1789px #AA076B, 825px 1237px #AA076B, 1067px 543px #AA076B, 962px 990px #AA076B,
		1606px 1387px #AA076B, 884px 1555px #AA076B, 1209px 1830px #AA076B, 439px 535px #AA076B,
		360px 1451px #AA076B, 1018px 1837px #AA076B, 1964px 677px #AA076B, 1095px 159px #AA076B,
		390px 245px #AA076B, 1869px 597px #AA076B, 1857px 544px #AA076B, 745px 1186px #AA076B,
		336px 1867px #AA076B, 250px 1377px #AA076B, 1872px 168px #AA076B, 878px 1299px #AA076B,
		1257px 177px #AA076B, 984px 303px #AA076B, 544px 303px #AA076B, 1250px 1052px #AA076B,
		152px 1322px #AA076B, 1466px 289px #AA076B, 1104px 1116px #AA076B, 1796px 482px #AA076B,
		1086px 525px #AA076B, 1540px 1822px #AA076B, 196px 1999px #AA076B, 1893px 771px #AA076B,
		1171px 1728px #AA076B, 1996px 710px #AA076B, 436px 370px #AA076B, 1270px 1570px #AA076B,
		55px 900px #AA076B, 1875px 1291px #AA076B, 337px 1251px #AA076B, 25px 5px #AA076B,
		1619px 835px #AA076B, 410px 676px #AA076B, 956px 531px #AA076B, 90px 489px #AA076B,
		561px 576px #AA076B, 907px 45px #AA076B, 1657px 810px #AA076B, 1029px 1705px #AA076B,
		1281px 828px #AA076B, 466px 961px #AA076B, 674px 1105px #AA076B, 456px 918px #AA076B,
		331px 148px #AA076B, 1105px 1681px #AA076B, 922px 319px #AA076B, 1600px 1403px #AA076B,
		420px 977px #AA076B, 166px 1637px #AA076B, 1033px 1668px #AA076B, 1884px 208px #AA076B,
		552px 720px #AA076B, 1543px 119px #AA076B, 587px 103px #AA076B, 348px 1766px #AA076B,
		664px 761px #AA076B, 565px 1253px #AA076B, 1472px 1332px #AA076B, 471px 1569px #AA076B,
		747px 76px #AA076B, 1429px 308px #AA076B, 1850px 597px #AA076B, 1148px 1085px #AA076B,
		307px 208px #AA076B, 253px 736px #AA076B, 148px 122px #AA076B, 366px 1163px #AA076B,
		1589px 1436px #AA076B, 1359px 1027px #AA076B, 1805px 95px #AA076B, 910px 837px #AA076B,
		1578px 976px #AA076B, 954px 710px #AA076B, 743px 1552px #AA076B, 271px 686px #AA076B,
		1960px 1336px #AA076B, 740px 1431px #AA076B, 972px 1184px #AA076B, 1690px 1942px #AA076B,
		1222px 839px #AA076B, 1151px 164px #AA076B, 1248px 871px #AA076B, 893px 1975px #AA076B,
		1979px 859px #AA076B, 139px 642px #AA076B, 856px 523px #AA076B, 879px 1186px #AA076B,
		1440px 1036px #AA076B, 1624px 1411px #AA076B, 1320px 1239px #AA076B, 1747px 1662px #AA076B,
		1155px 765px #AA076B, 885px 43px #AA076B, 552px 1690px #AA076B, 731px 293px #AA076B,
		1655px 1809px #AA076B, 187px 1766px #AA076B, 811px 1318px #AA076B, 716px 241px #AA076B,
		633px 668px #AA076B, 916px 1359px #AA076B, 1054px 620px #AA076B, 1038px 1345px #AA076B,
		1334px 1118px #AA076B, 393px 52px #AA076B, 1722px 590px #AA076B, 1329px 244px #AA076B,
		1388px 1983px #AA076B, 66px 1845px #AA076B, 172px 995px #AA076B, 1258px 1766px #AA076B,
		310px 110px #AA076B, 1019px 1705px #AA076B, 394px 1471px #AA076B, 489px 1962px #AA076B,
		183px 1155px #AA076B, 90px 1153px #AA076B, 845px 60px #AA076B, 403px 1930px #AA076B,
		820px 256px #AA076B, 394px 1716px #AA076B, 1744px 690px #AA076B, 831px 1562px #AA076B,
		110px 1000px #AA076B, 1604px 585px #AA076B, 1954px 1442px #AA076B, 397px 1085px #AA076B,
		242px 1785px #AA076B, 1216px 1885px #AA076B, 1873px 1700px #AA076B, 1181px 8px #AA076B,
		1978px 701px #AA076B, 1968px 391px #AA076B, 1621px 947px #AA076B, 1142px 1244px #AA076B,
		558px 584px #AA076B, 881px 767px #AA076B, 96px 960px #AA076B, 1143px 262px #AA076B,
		690px 1357px #AA076B, 218px 1363px #AA076B, 1507px 16px #AA076B, 1947px 1785px #AA076B,
		755px 1928px #AA076B, 1620px 1126px #AA076B, 1194px 1932px #AA076B, 728px 1068px #AA076B,
		542px 1765px #AA076B, 1159px 1334px #AA076B, 1284px 444px #AA076B, 1623px 1161px #AA076B,
		190px 1011px #AA076B, 855px 1379px #AA076B, 1244px 30px #AA076B, 1144px 1873px #AA076B,
		1276px 958px #AA076B, 1675px 1097px #AA076B, 354px 1735px #AA076B, 1534px 235px #AA076B,
		560px 1850px #AA076B, 493px 1737px #AA076B, 782px 598px #AA076B, 1709px 1377px #AA076B,
		717px 1040px #AA076B;
	animation: animStar 100s linear infinite;
	&:after {
		content: " ";
		position: absolute;
		top: 2000px;
		width: 2px;
		height: 2px;
		background: transparent;
		box-shadow: 592px 134px #AA076B, 346px 741px #AA076B, 1000px 1781px #AA076B,
			996px 728px #AA076B, 1085px 1462px #AA076B, 526px 1128px #AA076B, 1951px 557px #AA076B,
			673px 92px #AA076B, 415px 776px #AA076B, 1570px 643px #AA076B, 121px 697px #AA076B,
			782px 1847px #AA076B, 1853px 256px #AA076B, 910px 195px #AA076B, 362px 1201px #AA076B,
			52px 1627px #AA076B, 387px 899px #AA076B, 547px 1995px #AA076B, 1298px 440px #AA076B,
			1474px 1713px #AA076B, 1109px 1322px #AA076B, 1720px 1237px #AA076B, 473px 1196px #AA076B,
			1682px 580px #AA076B, 1476px 655px #AA076B, 1254px 731px #AA076B, 1069px 1840px #AA076B,
			291px 1789px #AA076B, 825px 1237px #AA076B, 1067px 543px #AA076B, 962px 990px #AA076B,
			1606px 1387px #AA076B, 884px 1555px #AA076B, 1209px 1830px #AA076B, 439px 535px #AA076B,
			360px 1451px #AA076B, 1018px 1837px #AA076B, 1964px 677px #AA076B, 1095px 159px #AA076B,
			390px 245px #AA076B, 1869px 597px #AA076B, 1857px 544px #AA076B, 745px 1186px #AA076B,
			336px 1867px #AA076B, 250px 1377px #AA076B, 1872px 168px #AA076B, 878px 1299px #AA076B,
			1257px 177px #AA076B, 984px 303px #AA076B, 544px 303px #AA076B, 1250px 1052px #AA076B,
			152px 1322px #AA076B, 1466px 289px #AA076B, 1104px 1116px #AA076B, 1796px 482px #AA076B,
			1086px 525px #AA076B, 1540px 1822px #AA076B, 196px 1999px #AA076B, 1893px 771px #AA076B,
			1171px 1728px #AA076B, 1996px 710px #AA076B, 436px 370px #AA076B, 1270px 1570px #AA076B,
			55px 900px #AA076B, 1875px 1291px #AA076B, 337px 1251px #AA076B, 25px 5px #AA076B,
			1619px 835px #AA076B, 410px 676px #AA076B, 956px 531px #AA076B, 90px 489px #AA076B,
			561px 576px #AA076B, 907px 45px #AA076B, 1657px 810px #AA076B, 1029px 1705px #AA076B,
			1281px 828px #AA076B, 466px 961px #AA076B, 674px 1105px #AA076B, 456px 918px #AA076B,
			331px 148px #AA076B, 1105px 1681px #AA076B, 922px 319px #AA076B, 1600px 1403px #AA076B,
			420px 977px #AA076B, 166px 1637px #AA076B, 1033px 1668px #AA076B, 1884px 208px #AA076B,
			552px 720px #AA076B, 1543px 119px #AA076B, 587px 103px #AA076B, 348px 1766px #AA076B,
			664px 761px #AA076B, 565px 1253px #AA076B, 1472px 1332px #AA076B, 471px 1569px #AA076B,
			747px 76px #AA076B, 1429px 308px #AA076B, 1850px 597px #AA076B, 1148px 1085px #AA076B,
			307px 208px #AA076B, 253px 736px #AA076B, 148px 122px #AA076B, 366px 1163px #AA076B,
			1589px 1436px #AA076B, 1359px 1027px #AA076B, 1805px 95px #AA076B, 910px 837px #AA076B,
			1578px 976px #AA076B, 954px 710px #AA076B, 743px 1552px #AA076B, 271px 686px #AA076B,
			1960px 1336px #AA076B, 740px 1431px #AA076B, 972px 1184px #AA076B, 1690px 1942px #AA076B,
			1222px 839px #AA076B, 1151px 164px #AA076B, 1248px 871px #AA076B, 893px 1975px #AA076B,
			1979px 859px #AA076B, 139px 642px #AA076B, 856px 523px #AA076B, 879px 1186px #AA076B,
			1440px 1036px #AA076B, 1624px 1411px #AA076B, 1320px 1239px #AA076B,
			1747px 1662px #AA076B, 1155px 765px #AA076B, 885px 43px #AA076B, 552px 1690px #AA076B,
			731px 293px #AA076B, 1655px 1809px #AA076B, 187px 1766px #AA076B, 811px 1318px #AA076B,
			716px 241px #AA076B, 633px 668px #AA076B, 916px 1359px #AA076B, 1054px 620px #AA076B,
			1038px 1345px #AA076B, 1334px 1118px #AA076B, 393px 52px #AA076B, 1722px 590px #AA076B,
			1329px 244px #AA076B, 1388px 1983px #AA076B, 66px 1845px #AA076B, 172px 995px #AA076B,
			1258px 1766px #AA076B, 310px 110px #AA076B, 1019px 1705px #AA076B, 394px 1471px #AA076B,
			489px 1962px #AA076B, 183px 1155px #AA076B, 90px 1153px #AA076B, 845px 60px #AA076B,
			403px 1930px #AA076B, 820px 256px #AA076B, 394px 1716px #AA076B, 1744px 690px #AA076B,
			831px 1562px #AA076B, 110px 1000px #AA076B, 1604px 585px #AA076B, 1954px 1442px #AA076B,
			397px 1085px #AA076B, 242px 1785px #AA076B, 1216px 1885px #AA076B, 1873px 1700px #AA076B,
			1181px 8px #AA076B, 1978px 701px #AA076B, 1968px 391px #AA076B, 1621px 947px #AA076B,
			1142px 1244px #AA076B, 558px 584px #AA076B, 881px 767px #AA076B, 96px 960px #AA076B,
			1143px 262px #AA076B, 690px 1357px #AA076B, 218px 1363px #AA076B, 1507px 16px #AA076B,
			1947px 1785px #AA076B, 755px 1928px #AA076B, 1620px 1126px #AA076B, 1194px 1932px #AA076B,
			728px 1068px #AA076B, 542px 1765px #AA076B, 1159px 1334px #AA076B, 1284px 444px #AA076B,
			1623px 1161px #AA076B, 190px 1011px #AA076B, 855px 1379px #AA076B, 1244px 30px #AA076B,
			1144px 1873px #AA076B, 1276px 958px #AA076B, 1675px 1097px #AA076B, 354px 1735px #AA076B,
			1534px 235px #AA076B, 560px 1850px #AA076B, 493px 1737px #AA076B, 782px 598px #AA076B,
			1709px 1377px #AA076B, 717px 1040px #AA076B;
	}
}

/* #AA076B */
#stars3 {
	width: 3px;
	height: 3px;
	background: transparent;
	box-shadow: 222px 946px #AA076B, 408px 926px #AA076B, 1608px 1741px #AA076B,
		1977px 1436px #AA076B, 713px 1152px #AA076B, 139px 39px #AA076B, 497px 1590px #AA076B,
		1808px 1652px #AA076B, 598px 667px #AA076B, 1962px 75px #AA076B, 331px 1245px #AA076B,
		1600px 1822px #AA076B, 1463px 883px #AA076B, 299px 335px #AA076B, 1229px 909px #AA076B,
		448px 581px #AA076B, 1346px 571px #AA076B, 1247px 458px #AA076B, 799px 186px #AA076B,
		643px 404px #AA076B, 1330px 790px #AA076B, 299px 699px #AA076B, 1593px 1891px #AA076B,
		934px 1230px #AA076B, 1162px 1835px #AA076B, 903px 1024px #AA076B, 596px 900px #AA076B,
		1223px 1078px #AA076B, 682px 1024px #AA076B, 151px 373px #AA076B, 54px 1586px #AA076B,
		657px 1902px #AA076B, 812px 273px #AA076B, 705px 1142px #AA076B, 1902px 1353px #AA076B,
		1222px 1980px #AA076B, 767px 158px #AA076B, 22px 663px #AA076B, 1055px 727px #AA076B,
		1728px 750px #AA076B, 223px 287px #AA076B, 438px 127px #AA076B, 224px 1146px #AA076B,
		1594px 1466px #AA076B, 1505px 436px #AA076B, 389px 1049px #AA076B, 596px 435px #AA076B,
		1997px 1798px #AA076B, 29px 51px #AA076B, 40px 514px #AA076B, 1779px 769px #AA076B,
		652px 1211px #AA076B, 1599px 347px #AA076B, 1854px 1941px #AA076B, 485px 1633px #AA076B,
		697px 1894px #AA076B, 1772px 877px #AA076B, 555px 1030px #AA076B, 976px 1338px #AA076B,
		541px 1112px #AA076B, 817px 899px #AA076B, 961px 923px #AA076B, 115px 1321px #AA076B,
		875px 434px #AA076B, 1577px 1980px #AA076B, 1160px 131px #AA076B, 15px 160px #AA076B,
		979px 56px #AA076B, 959px 1756px #AA076B, 1358px 435px #AA076B, 563px 591px #AA076B,
		1284px 475px #AA076B, 341px 601px #AA076B, 74px 471px #AA076B, 416px 584px #AA076B,
		692px 536px #AA076B, 1041px 377px #AA076B, 1601px 1923px #AA076B, 1014px 241px #AA076B,
		122px 1594px #AA076B, 1510px 280px #AA076B, 195px 1720px #AA076B, 152px 104px #AA076B,
		1686px 811px #AA076B, 1329px 750px #AA076B, 1655px 441px #AA076B, 1288px 1040px #AA076B,
		1802px 1368px #AA076B, 767px 615px #AA076B, 302px 195px #AA076B, 1973px 1902px #AA076B,
		1088px 1583px #AA076B, 1840px 1126px #AA076B, 500px 111px #AA076B, 1263px 667px #AA076B,
		1748px 121px #AA076B, 1608px 364px #AA076B, 1064px 1610px #AA076B, 1682px 1826px #AA076B,
		1747px 1800px #AA076B;
	animation: animStar 150s linear infinite;
	&:after {
		content: "";
		position: absolute;
		top: 2000px;
		width: 3px;
		height: 3px;
		background: transparent;
		box-shadow: 222px 946px #AA076B, 408px 926px #AA076B, 1608px 1741px #AA076B,
			1977px 1436px #AA076B, 713px 1152px #AA076B, 139px 39px #AA076B, 497px 1590px #AA076B,
			1808px 1652px #AA076B, 598px 667px #AA076B, 1962px 75px #AA076B, 331px 1245px #AA076B,
			1600px 1822px #AA076B, 1463px 883px #AA076B, 299px 335px #AA076B, 1229px 909px #AA076B,
			448px 581px #AA076B, 1346px 571px #AA076B, 1247px 458px #AA076B, 799px 186px #AA076B,
			643px 404px #AA076B, 1330px 790px #AA076B, 299px 699px #AA076B, 1593px 1891px #AA076B,
			934px 1230px #AA076B, 1162px 1835px #AA076B, 903px 1024px #AA076B, 596px 900px #AA076B,
			1223px 1078px #AA076B, 682px 1024px #AA076B, 151px 373px #AA076B, 54px 1586px #AA076B,
			657px 1902px #AA076B, 812px 273px #AA076B, 705px 1142px #AA076B, 1902px 1353px #AA076B,
			1222px 1980px #AA076B, 767px 158px #AA076B, 22px 663px #AA076B, 1055px 727px #AA076B,
			1728px 750px #AA076B, 223px 287px #AA076B, 438px 127px #AA076B, 224px 1146px #AA076B,
			1594px 1466px #AA076B, 1505px 436px #AA076B, 389px 1049px #AA076B, 596px 435px #AA076B,
			1997px 1798px #AA076B, 29px 51px #AA076B, 40px 514px #AA076B, 1779px 769px #AA076B,
			652px 1211px #AA076B, 1599px 347px #AA076B, 1854px 1941px #AA076B, 485px 1633px #AA076B,
			697px 1894px #AA076B, 1772px 877px #AA076B, 555px 1030px #AA076B, 976px 1338px #AA076B,
			541px 1112px #AA076B, 817px 899px #AA076B, 961px 923px #AA076B, 115px 1321px #AA076B,
			875px 434px #AA076B, 1577px 1980px #AA076B, 1160px 131px #AA076B, 15px 160px #AA076B,
			979px 56px #AA076B, 959px 1756px #AA076B, 1358px 435px #AA076B, 563px 591px #AA076B,
			1284px 475px #AA076B, 341px 601px #AA076B, 74px 471px #AA076B, 416px 584px #AA076B,
			692px 536px #AA076B, 1041px 377px #AA076B, 1601px 1923px #AA076B, 1014px 241px #AA076B,
			122px 1594px #AA076B, 1510px 280px #AA076B, 195px 1720px #AA076B, 152px 104px #AA076B,
			1686px 811px #AA076B, 1329px 750px #AA076B, 1655px 441px #AA076B, 1288px 1040px #AA076B,
			1802px 1368px #AA076B, 767px 615px #AA076B, 302px 195px #AA076B, 1973px 1902px #AA076B,
			1088px 1583px #AA076B, 1840px 1126px #AA076B, 500px 111px #AA076B, 1263px 667px #AA076B,
			1748px 121px #AA076B, 1608px 364px #AA076B, 1064px 1610px #AA076B, 1682px 1826px #AA076B,
			1747px 1800px #AA076B;
	}
}

@keyframes animStar {
	from {
		transform: translateY(0px);
	}

	to {
		transform: translateY(-2000px);
	}
}