@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@100;200;300;400;500&family=Inter+Tight:ital,wght@0,100;0,200;1,100;1,200&family=Roboto:wght@100&family=Ubuntu:wght@500&display=swap");

.stayconnected__container {
  color: #ffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem;
  padding-bottom: 1rem;
}
.stayconnected__container > h1{
  font-family: "Ubuntu";
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
.footer_icons {
  display: flex;
  justify-content: center;
  gap: 3rem;
}
.footer_icons > a {
  color: #000000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 1.56rem;
  height: 1.56rem;
  font-size: 1.25rem;
  padding: 0.625rem 0.625rem;
  background: #ffffff;
}

.footer_icons > a:hover {
  background: var(--themeColour);
  color: #ffff;
  transition: 0.5s;
}

@media only screen and (max-width: 1300px) {
}
@media (min-device-width: 20px) and (max-device-width: 500px) {
}
@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1100px) {
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 800px) {
  .stayconnected__container > h1 {
    font-size: 15px;
  }
  /* .stayconnected__container { 
    margin-top: 11rem;
  } */
}

@media screen and (max-width: 600px) {
}
@media screen and (max-width: 500px) {
  .stayconnected__container > h1{
    font-size: 13px;
  }
  /* .stayconnected__container { 
    margin-top: 13rem;
  } */
}

@media screen and (max-width: 400px) {
  .footer_icons {
    gap: 1.6rem;
  }
  .footer_icons > a {
    width: 1.30rem;
    height: 1.30rem;
  }
  /* .stayconnected__container { 
    margin-top: 15rem;
  } */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@media only screen and (device-width: 768px) {
}