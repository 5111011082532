body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: radial-gradient(200.65% 776.86% at -9.28% 116.91%,#140915 35.44%,var(--themeColour) 100%); */
  /* background: radial-gradient(
    200.65% 776.86% at -9.28% 116.91%,
    #140915 15.44%,
    #80489C 100%
  ); */
  background: radial-gradient(200.65% 776.86% at -9.28% 116.91%,#140915 15.44%,#aa0761 100%);
 }
/* #ad5389 */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 :root {
  /* --themeColour: #2cb7c7; */
  /* --themeColour: radial-gradient(
    200.65% 776.86% at -9.28% 116.91%,
    #904e95 15.44%,
    #3c1053 100%
  ); */
  --themeColour: #aa0761;
  --primary-font:"Karla", sans-serif;
 }