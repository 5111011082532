.support__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 5rem;
  gap: 3rem;
}
.support__main_contnet {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--themeColour);
  font-family: var(--primary-font);
}
.support__main_contnet > h1 {
  font-size: 2.5rem;
  background: linear-gradient(90deg, #e10c5d 0%, #f9681c 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.support__main_contnet > p {
  width: 60%;
  color: #ffff;
  text-align: center;
  font-size: 1.5rem;
}
.support__main_contnet a {
  color: var(--themeColour);
}
.contact__section {
  width: 80%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3rem;
}
.contact_card {
  display: flex;
  flex-direction: column;
  width: 15rem;
  border: 1px solid var(--themeColour);
  justify-content: center;
  border-radius: 20px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ctct__name {
  color: #ffff;
}
.ctct__icons_container {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-evenly;
}
