.hero__section_main {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}
.hero__desc_area{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
.hero__desc_area >p{
  font-size: 22px;
  color: #ebe9e9;
  text-align: center;
}
.excel__logo_container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 1px;
  justify-content: center;
  align-items: center;
}
.excel__logo_container > p {
  font-size: 38px;
  font-family:"Karla", sans-serif;
  color: #ebe9e9;
}

.excel_logo{
  width: 14rem;
  /* height: 9rem; */
  /* cursor: pointer; */
  /* pointer-events: none; */
}
.hero__desc_area > h1 {
  font-size:70px;
  font-weight: bold;
  line-height: 1px;
  color: #f4f4f5;
  text-align: center;
}
.hero__section_main > p {
  font-family: "Karla", sans-serif;
  text-align: center;
  padding-bottom: 2rem;
  font-size: 22px;
  color: #ebe9e9;
}

.video_btn {
  position: relative;
  cursor: pointer;
  margin-top: 2rem;
  animation-name: beat;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: center;
}

@keyframes beat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.video_btn .play {
  position: relative;
  width: 5rem;
  height: 5rem;
  background: radial-gradient(229.65% 772.86% at -8.28% 116.91%,#f86 5.44%,#62063f 30%);

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.94rem 1.56rem #8e9b9e66;
  transition: 0.5s;
}

.video_btn.active .play {
  box-shadow: 0 0 0 150vh var(--themeColour);
}

.video_btn .play::before {
  content: "";
  position: absolute;
  border: 1.56rem solid #fff;
  border-top: 0.94rem solid transparent;
  border-bottom: 0.94rem solid transparent;
  border-right: 0px solid transparent;
  transform: translateX(4px);

}
/* video-container css*/
.video-container {
  position: relative;
  padding-top: 56.25%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video_clip{
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: radial-gradient(200.65% 776.86% at -9.28% 116.91%,#140915 25.44%,var(--themeColour) 100%);
    transition: 0.3 ease-in;
}

.video_clip.active {
  transition-delay: 0.5s;
  transform: translate(-50%, -50%) scale(1);
}

.video_clip video {
  width: 100%;
  outline: none;
  box-shadow: 0 0.94rem 2.19rem rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 1300px) {
}
@media (min-device-width: 20px) and (max-device-width: 500px) {
}
@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1100px) {
}

@media screen and (max-width: 992px) {
  
}

@media screen and (max-width: 900px) {
  .hero__section_main > p {
    font-size: 1.2rem;
  }
}


@media screen and (max-width: 800px) {
  .hero__section_main{
    gap: 10px;
  }
  .hero__desc_area > h1 {
    font-size: 39px;
  }
  .video_btn .play {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    box-shadow: 0 0.94rem 1.56rem #8e9b9e66;
  }
  .excel__logo_container{
    font-size: 30px;
  }
  .hero__desc_area >p{
    font-size: 14px;
  }
}
@media screen and (max-width: 700px) {
  .excel_logo{
    width: 12rem;
    /* height: 8rem; */
  }
}

@media screen and (max-width: 600px) {
  .video_btn .play {
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
    box-shadow: 0 0.94rem 1.56rem #8e9b9e66;
  }
}
@media screen and (max-width: 500px) {
  .hero__desc_area > h1 {
    font-size: 1.5rem;
  }
  .hero__section_main > p {
    font-size: 1.1rem;
  }
  .video_btn .play {
    width: 3.9rem;
    height: 3.9rem;
    border-radius: 50%;
    box-shadow: 0 0.94rem 1.56rem #8e9b9e66;
  }
  .video_btn .play::before {
    border-top: 0.85rem solid transparent;
    border-bottom: 0.85rem solid transparent;
    transform: translateX(3px);
  }
}

@media screen and (max-width: 400px) {
  .hero__section_main > p {
    font-size: 0.8rem;
  }
  .video_btn .play {
    width: 3.65rem;
    height: 3.65rem;
    border-radius: 50%;
    box-shadow: 0 0.94rem 1.56rem #8e9b9e66;
  }
  .video_btn .play::before {
    border-top: 0.75rem solid transparent;
    border-bottom: 0.75rem solid transparent;
    transform: translateX(3px);
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@media only screen and (device-width: 768px) {
}